<template>
  <div class="billDiffHistory">
    <!--搜索条件区域-->
    <div class="searchWrapper" @keydown.enter.prevent="searchData">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form
        :inline="true"
        label-position="right"
        label-width="110px"
        :model="formInline"
        class="demo-form-inline"
      >
        <div class="col_box">
          <div class="col_left">
            <el-form-item label="" style="min-width: 120px">
              <el-select v-model.trim="formInline.dateType" filterable placeholder="">
                <el-option :label="$t('searchModule.Payment_Time')" value="0"></el-option>
                <el-option :label="$t('searchModule.Refund_time')" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker
                v-model="dateTime"
                type="daterange"
                :clearable="false"
                @change="dateTimeChange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="" style="min-width: 120px">
              <el-select v-model="formInline.type" style="width: 120px">
                <el-option :label="$t('searchModule.Our_serial_number')" value="0"></el-option>
                <el-option label="对方流水号" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="formInline.orderNo"></el-input>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >查询
            </el-button>
            <el-button type="" icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
          </div>
        </div>

        <!-- v-if='authority.tabs["8009040012"].button.export' -->
        <!--<el-button type="primary" icon="el-icon-upload2" @click="exportData">{{ $t('button.export') }}</el-button>-->
      </el-form>
    </div>
    <div class="tableWrapper">
      <el-table
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        ref="singleTable"
        highlight-current-row
      >
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="80"
          v-if="authority.tabs['payRepeat2'].button.detail"
        >
          <template slot-scope="scope">
            <el-dropdown style="color: #20a0ff; cursor: pointer" @command="ctrlData(scope.row)">
              <el-button type="text" size="small" style="padding: 0">
                操作
                <i class="el-icon-arrow-down" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="detaile"
                  v-if="authority.tabs['payRepeat2'].button.detail"
                  >详情
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页器-->
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          v-if="total != 0"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--弹出框详情-->
    <billDiffDetail
      ref="billDiffDetail"
      :mynumber="mynumber"
      :title="title"
      detailType="detail"
      :transFlowList="transFlowList"
      :transFlowListLabel="transFlowListLabel"
      :orderList="orderList"
      :orderListLabel="orderListLabel"
    ></billDiffDetail>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
import billDiffDetail from "@/components/dialogDetail/billDiffDetail.vue";
export default {
  name: "payRepeatRefund",
  components: {
    billDiffDetail,
  },
  data() {
    return {
      authority: this.$route.meta.authority,
      dateTime: [],
      title: "退款",
      mynumber: 2,
      total: 0,
      page: 1,
      pageSize: 15,
      formInline: {
        dateType: "0",
        type: "0",
        orderNo: "",
      },
      loading: false,
      tableData: [],
      tableCols: [
        {
          label: "我方流水号",
          prop: "sysTradeNo",
        },
        {
          label: this.$t("list.refund_amount"),
          prop: "refundMoney",
          formatter: (row) => {
            if (row.refundMoney) {
              return (row.refundMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付方式",
          prop: "payTypeDesc",
          // ,
          // formatter: (row) => {
          //   switch (row.payTypeDesc) {
          //     case 1:
          //       return '余额支付'
          //     case 2:
          //       return '现金支付'
          //     case 3:
          //       return '微信H5支付'
          //     case 4:
          //       return '微信app支付'
          //     case 5:
          //       return '支付宝app支付'
          //     case 6:
          //       return '微信扫码支付'
          //     case 7:
          //       return '支付宝扫码支付'
          //     case 8:
          //       return '微信二维码支付'
          //     case 9:
          //       return '支付宝二维码支付'
          //     case 10:
          //       return '微信静态二维码支付'
          //     case 11:
          //       return '支付宝态二维码支付'
          //     default:
          //       return ''
          //   }
          // }
        },
        {
          label: this.$t("list.payment_time"),
          prop: "payTime",
        },
        {
          label: "对方流水号",
          prop: "outTradeNo",
        },
        {
          label: this.$t("list.Refund_time"),
          prop: "refundTime",
        },
        {
          label: this.$t("list.Operator"),
          prop: "operatorName",
        },
      ],
      transFlowList: [],
      transFlowListLabel: [
        {
          label: "我方流水号",
          prop: "sysTradeNo",
        },
        {
          label: this.$t("list.Payment_channels"),
          prop: "billTypeDesc",
          // ,
          // formatter: (row) => {
          //   if (row.billType == 1) {
          //     return '支付宝'
          //   } else if (row.billType == 2) {
          //     return '微信'
          //   } else if (row.billType == 3) {
          //     return '银联'
          //   } else {
          //     return '其他'
          //   }
          // }
        },
        {
          label: this.$t("list.payment_time"),
          prop: "dealTime",
        },
        {
          label: "交易金额",
          prop: "money",
          formatter(row, column, cellValue, index) {
            if (row.money) {
              if (index == "1" && row.money != row.refundMoney) {
                return `${(row.money / 100).toFixed(2)}(退${(row.refundMoney / 100).toFixed(2)}元)`;
              } else {
                return (row.money / 100).toFixed(2);
              }
            } else {
              return "0.00";
            }
          },
        },
        // {
        //   label: '支付场景',
        //   prop: 'orderType',
        //   formatter (row) {
        //     if (row.orderType) {
        //       switch (row.orderType) {
        //         case 0:
        //           return '余额充值'
        //         case 1:
        //           return '支付停车费'
        //         case 2:
        //           return '购买停车卡'
        //         default:
        //           return '暂无'
        //       }
        //     } else {
        //       return '暂无'
        //     }
        //   }
        // },
        {
          label: "支付场景",
          prop: "orderTypeDesc",
        },
        {
          label: "对方流水号",
          prop: "outTradeNo",
        },
        {
          label: "重复我方流水号",
          prop: "repeatSysTradeNo",
        },
      ],
      orderList: [],
      orderListLabel: [
        {
          label: "订单号",
          prop: "businessOrderId",
        },
        {
          label: this.$t("list.plate_number"),
          prop: "plateNumber",
        },
        {
          label: this.$t("list.park_name"),
          prop: "parkName",
        },
        {
          label: this.$t("list.entry_time"),
          prop: "entryTime",
        },
        {
          label: this.$t("list.Appearance_time"),
          prop: "exitTime",
        },
        {
          label: this.$t("list.order_amount"),
          prop: "money",
          formatter: (row) => {
            if (row.money) {
              return (row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付方式",
          prop: "payTypeDesc",
        },
        {
          label: this.$t("list.payment_time"),
          prop: "payTime",
        },
        {
          label: "我方流水号",
          prop: "paymentId",
        },
      ],
    };
  },
  methods: {
    resetForm() {
      let startTime = new Date();
      let endTime = new Date();
      startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 30);
      this.formInline = {
        dateType: "0",
        type: "0",
        orderNo: "",
      };
      this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
      this.formInline.startDate = dateFormat(startTime, "yyyy-MM-dd");
      this.formInline.endDate = dateFormat(endTime, "yyyy-MM-dd");
    },
    getDetail(data) {
      console.log("data", data);
      this.$axios
        .get("/acb/2.0/repeatPayOrder/refundDetail", {
          data: {
            flatAccountRecordId: data.flatAccountRecordId,
          },
        })
        .then((res) => {
          this.transFlowList = res.value.transFlowList;
          this.orderList = res.value.orderList;
          this.$refs.billDiffDetail.dialogVisibleShow(res.value.remark);
        });
    },
    ctrlData(data) {
      this.getDetail(data);
    },
    dateTimeChange(val) {
      this.formInline.startDate = val[0];
      this.formInline.endDate = val[1];
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    validateTime() {
      let diff =
        new Date(this.dateTime[1]).getTime() / (1000 * 3600 * 24) -
        new Date(this.dateTime[0]).getTime() / (1000 * 3600 * 24);
      if (diff > 30) {
        this.$alert("最多查询31天数据");
        return false;
      } else {
        return true;
      }
    },
    searchData() {
      if (!this.validateTime()) return false;
      this.formInline.page = this.page;
      this.formInline.pageSize = this.pageSize;
      this.$axios
        .get("/acb/2.0/repeatPayOrder/refundRecordList", {
          data: this.formInline,
        })
        .then((res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
        })
        .catch((_) => {
          this.tableData = [];
          this.total = 0;
        });
    },
  },
  mounted() {
    let startTime = new Date();
    let endTime = new Date();
    startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 30);
    this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
    this.formInline.startDate = dateFormat(startTime, "yyyy-MM-dd");
    this.formInline.endDate = dateFormat(endTime, "yyyy-MM-dd");
  },
};
</script>

<style lang="stylus" scoped rel="stylesheet/stylus">

.pagerWrapper
  text-align right
  margin-top 28px
  font-size 12px
</style>
