<template>
  <div class="billDiff">
    <!--搜索条件区域-->
    <div class="searchWrapper" @keydown.enter.prevent="searchData">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
        <div class="col_box">
          <div class="col_left">
            <el-form-item label="支付日期">
              <el-date-picker
                v-model="dateTime"
                :clearable="false"
                @change="dateTimeChange"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="" style="min-width: 120px">
              <el-select v-model="formInline.type" style="width: 120px">
                <el-option :label="$t('searchModule.Our_serial_number')" value="1"></el-option>
                <!-- <el-option label="对方流水号" value="1"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="formInline.orderNo"></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Channel_serial_number')">
              <el-input
                v-model="formInline.tradeNo"
                placeholder="渠道流水号"
                style="width: 240px"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Operator')">
              <el-select v-model.trim="formInline.ren" filterable size="15">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in renList"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >查询
            </el-button>
            <el-button type="" icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
          </div>
        </div>
        <div class="col_box_boder" v-if="authority.tabs['payRepeat1'].button.export"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <el-button
              type=""
              icon="el-icon-upload2"
              @click="exportData"
              v-if="authority.tabs['payRepeat1'].button.export"
              >{{ $t('button.export') }}</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        ref="singleTable"
        highlight-current-row
      >
        <el-table-column type="selection" align="center" width="55"> </el-table-column>
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="80"
          v-if="
            authority.tabs['payRepeat1'].button.detail || authority.tabs['payRepeat1'].button.refund
          "
        >
          <template slot-scope="scope">
            <el-dropdown
              style="color: #20a0ff; cursor: pointer"
              @command="handleCommand($event, scope.row)"
            >
              <el-button type="text" size="small" style="padding: 0">
                操作
                <i class="el-icon-arrow-down" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="detail" v-if="authority.tabs['payRepeat1'].button.detail"
                  >详情</el-dropdown-item
                >
                <el-dropdown-item command="refund" v-if="authority.tabs['payRepeat1'].button.refund"
                  >退款</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页器-->
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          v-if="total != 0"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--弹出框详情-->
    <billDiffDetail
      :title="title"
      ref="billDiffDetail"
      :title1="title1"
      :mynumber="mynumber"
      :refundData="refundData"
      :detailType="detailType"
      :transFlowList="transFlowList"
      :transFlowListLabel="transFlowListLabel"
      :orderList="orderList"
      :orderListLabel="orderListLabel"
    ></billDiffDetail>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import billDiffDetail from "@/components/dialogDetail/billDiffDetail.vue";
export default {
  name: "payRepeatList",
  components: {
    billDiffDetail,
  },
  data() {
    return {
      tradeNo: "",
      renList: [],
      authority: this.$route.meta.authority,
      refundData: {},
      detailType: "payRepeatList",
      transFlowListIds: [],
      title: "退款",
      title1: "退款",
      total: 0,
      page: 1,
      pageSize: 15,
      mynumber: 2,
      dateTime: [],
      formInline: {
        startDate: "",
        endDate: "",
        orderNo: "",
        type: "",
        ren: "",
      },
      loading: false,
      tableData: [],
      tableCols: [
        {
          label: "支付方式",
          prop: "payTypeDesc",
        },
        {
          label: "支付金额",
          prop: "money",
          formatter: (row) => {
            if (row.money) {
              return (row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.payment_time"),
          prop: "payTime",
        },
        {
          label: "我方流水号",
          prop: "rePaymentId",
        },
        {
          label: "渠道流水号",
          prop: "tradeNo",
        },
      ],
      transFlowList: [],
      transFlowListLabel: [
        {
          label: "我方流水号",
          prop: "sysTradeNo",
        },
        {
          label: this.$t("list.Payment_channels"),
          prop: "billTypeDesc",
        },
        {
          label: this.$t("list.payment_time"),
          prop: "dealTime",
        },
        {
          label: "交易金额",
          prop: "money",
          formatter(row, column, cellValue, index) {
            if (row.money) {
              if (index == "1" && row.money != row.refundMoney) {
                return `${(row.money / 100).toFixed(2)}(退${(row.refundMoney / 100).toFixed(2)}元)`;
              } else {
                return (row.money / 100).toFixed(2);
              }
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付场景",
          prop: "orderTypeDesc",
          formatter: (row) => {
            if (row.orderTypeDesc) {
              return row.orderTypeDesc;
            } else {
              return "暂无";
            }
          },
        },
        {
          label: "对方流水号",
          prop: "outTradeNo",
        },
        {
          label: "重复我方流水号",
          prop: "repeatSysTradeNo",
          formatter(row, column, cellValue, index) {
            if (row.repeatSysTradeNo) {
              if (index == "0") {
                return "";
              } else {
                return row.repeatSysTradeNo;
              }
            } else {
              return "";
            }
          },
        },
      ],
      orderList: [],
      orderListLabel: [
        {
          label: "订单号",
          prop: "businessOrderId",
        },
        {
          label: this.$t("list.park_name"),
          prop: "parkName",
        },
        {
          label: this.$t("list.plate_number"),
          prop: "plateNumber",
        },
        {
          label: this.$t("list.entry_time"),
          prop: "entryTime",
        },
        {
          label: this.$t("list.Appearance_time"),
          prop: "exitTime",
        },
        {
          label: this.$t("list.amount_receivable"),
          prop: "shouldPay",
          formatter: (row) => {
            if (row.shouldPay) {
              return (row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          prop: "agioPay",
          formatter: (row) => {
            if (row.agioPay) {
              return (row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          prop: "money",
          formatter: (row) => {
            if (row.money) {
              return (row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付方式",
          prop: "payTypeDesc",
        },
        {
          label: "支付状态",
          prop: "orderStatusDesc",
        },
        {
          label: "我方流水号",
          prop: "paymentId",
        },
      ],
    };
  },
  methods: {
    resetForm() {
      let startTime = new Date();
      let endTime = new Date();
      startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 30);
      this.formInline = {
        orderNo: "",
        type: "1",
      };
      this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
      this.formInline.startDate = dateFormat(startTime, "yyyy-MM-dd");
      this.formInline.endDate = dateFormat(endTime, "yyyy-MM-dd");
    },
    exportData() {
      exportExcelNew("/acb/2.0/repeatPayOrder/download", this.formInline);
    },
    dateTimeChange(val) {
      this.formInline.startDate = val[0];
      this.formInline.endDate = val[1];
    },
    // 处理操作相关动作
    handleCommand(command, data) {
      let url = null;
      if (command == "detail") {
        this.detailType = "detail";
        this.title = "重复支付";
        url = "/acb/2.0/repeatPayOrder/repeatPayDetail";
      } else if (command == "refund") {
        this.title = "退款";
        url = "/acb/2.0/repeatPayOrder/refundPreview";
        this.detailType = "payRepeatList";
      }
      this.getDetail(data, url);
    },
    getDetail(data, url) {
      this.$axios
        .get(url, {
          data: {
            id: data.id,
          },
        })
        .then((res) => {
          this.$refs.billDiffDetail.dialogVisibleShow();
          this.transFlowList = res.value.transFlowList;
          this.orderList = res.value.orderList;
          this.refundData = {
            paymentExceptionId: data.id,
            // repaymentId: data.paymentId,
            tradeNo: data.tradeNo,
            payOrderId: data.payOrderId,
            // payType: data.payType
            repaymentId: data.rePaymentId,
          };
        })
        .catch(() => {
          this.transFlowList = [];
          this.orderList = [];
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    validateTime() {
      let diff =
        new Date(this.dateTime[1]).getTime() / (1000 * 3600 * 24) -
        new Date(this.dateTime[0]).getTime() / (1000 * 3600 * 24);
      if (diff > 30) {
        this.$alert("最多查询31天数据");
        return false;
      } else {
        return true;
      }
    },
    searchData() {
      if (!this.validateTime()) return false;
      this.formInline.page = this.page;
      this.formInline.pageSize = this.pageSize;
      this.$axios
        .get("/acb/2.0/repeatPayOrder/list", {
          data: this.formInline,
        })
        .then((res) => {
          this.tableData = res.value.list;
          // eslint-disable-next-line no-bitwise
          this.total = (res.value.total * 1) | 0;
        })
        .catch((_) => {
          this.tableData = [];
          this.total = 0;
        });
    },
  },
  mounted() {
    let startTime = new Date();
    let endTime = new Date();
    startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 30);
    this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
    this.formInline = {
      orderNo: "",
      type: "1",
      startDate: dateFormat(startTime, "yyyy-MM-dd"),
      endDate: dateFormat(endTime, "yyyy-MM-dd"),
    };
    this.$EventBus.$on("payRepeatListSearchData", this.searchData);

    if (this.$route.params.orderNo) {
      console.log("this.$route.params.orderNo", this.$route.params.orderNo);
      this.formInline.orderNo = this.$route.params.orderNo;
    }
    this.searchData();
  },
};
</script>

<style lang="stylus" scoped rel="stylesheet/stylus">
.pagerWrapper
  text-align right
  margin-top 28px
  font-size 12px
</style>
