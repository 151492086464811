<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <el-tabs type="card" class="bgFFF" v-model="activeName" @tab-click="handleTab">
        <el-tab-pane v-if="authority.tabs['payRepeat1']" label="重复支付" name="1">
          <payRepeatList ref="payRepeatList"></payRepeatList>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['payRepeat2']" label="退款记录" name="2">
          <payRepeatRefund ref="payRepeatRefund"></payRepeatRefund>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import exportFile from "@/components/exportFile/exportFile.vue";
import payRepeatRefund from "./payRepeatRefund.vue";
import payRepeatList from "./payRepeatList.vue";

export default {
  name: "payRepeat",
  components: {
    payRepeatRefund,
    payRepeatList,
  },
  data() {
    return {
      authority: this.$route.meta.authority,
      activeName: "1",
    };
  },
  methods: {
    handleTab(vm) {
      vm.name == 1
        ? this.$refs.payRepeatList.searchData()
        : this.$refs.payRepeatRefund.searchData();
    },
  },
  created() {
    console.log("this.$route", this.$route);
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    background #EFF2F7
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    float right
    .export
      font-size 12px
      .iconfont
        margin-right 0
  .btnWrapper
    text-align left
    margin-top 28px
    float left
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
